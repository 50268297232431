$breakpoint-xs-max: 599px;

$breakpoint-sm-min: 600px;
$breakpoint-sm-max: 959px;

$breakpoint-md-min: 960px;
$breakpoint-md-max: 1279px;

$breakpoint-lg-min: 1280px;
$breakpoint-lg-max: 1919px;

$breakpoint-xl-min: 1920px;
$breakpoint-xl-max: 5000px;

// --
// Container sizes.
// --
$container-size-xs: 100%;
$container-size-sm: 564px;
$container-size-md: 912px;
$container-size-lg: 1248px;
$container-size-xl: 1500px;

// --
// Colors
// --
$primary-color: #ff9900;
$primary-color-light: #fff3cc;
$secondary-color: #706758;

// --
// Icon Fonts
// --
$icomoon-font-family: "dlc-icon" !default;
$icomoon-font-path: "." !default;

$dlc-chevron-left-circle: "\e900";
$dlc-chevron-right-circle: "\e901";
$dlc-circle-right: "\ea42";
$dlc-circle-left: "\ea44";
