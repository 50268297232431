@import "../variables";

@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2ij0c7")
            format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2ij0c7")
            format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2ij0c7##{$icomoon-font-family}")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="dlc-"],
[class*=" dlc-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dlc-chevron-left-circle {
    &:before {
        content: $dlc-chevron-left-circle;
    }
}
.dlc-chevron-right-circle {
    &:before {
        content: $dlc-chevron-right-circle;
    }
}
.dlc-circle-right {
    &:before {
        content: $dlc-circle-right;
    }
}
.dlc-circle-left {
    &:before {
        content: $dlc-circle-left;
    }
}
