@use "@angular/material" as mat;
@use "sass/theme/theme" as dlc-t;

@import "sass/breakpoint-mixins";

@import "sass/snackbar";
@import "sass/list";
@import "sass/form";
@import "sass/utils/spacing";
@import "sass/common";
@import "sass/fonts";

@include mat.core();
@include mat.all-component-themes(dlc-t.$dlc-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.loading-application {
  margin: 32px;
  text-align: center;
  font-size: 24px;
}

.page-container {
  height: calc(100% - 64px); // 64px being the height of the header/toolbar.
}

.content-wrapper {
  margin: 16px 0;
}

.required {
  $color: dlc-t.get-warn-color(500);
  color: $color;
}

.dlc-snackbar {
  &--success {
    background: dlc-t.get-green-palette-color(900);
  }

  &--error {
    background: dlc-t.get-warn-color(900);
  }
}

.page-title {
  margin: 10px 40px;

  small {
    font-size: 11px;
    font-weight: 200;
    color: gray;
    letter-spacing: 1.5px;
    margin-left: 8px;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toggle {
  padding: 4px 10px;
  border-radius: 4px;

  &--on {
    background-color: lightgreen;
    border: 1px solid green;
  }

  &--off {
    background-color: #d74848;
    border: 1px solid #cf0000;
    color: white;
  }
}

// == Skeleton Loading == //
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

// == Alert == //
div.alert {
  border-radius: 4px;
  padding: 4px 15px;
  margin-bottom: 10px;

  &-danger {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #e75d5d;
  }

  button {
    float: right;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 4px;
    font-size: 20px;
    color: inherit;
  }
}
