$spaceIncrement: 8; // In pixels.
$spaceAmounts: ();

@for $i from 1 through 10 {
    $spaceAmounts: append($spaceAmounts, $i);
}

$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceAmounts {
    $calcSpace: $space * $spaceIncrement;

    .m-#{$space} {
        margin: #{$calcSpace}px !important;
    }

    .p-#{$space} {
        padding: #{$calcSpace}px !important;
    }

    .mx-#{$space} {
        margin: 0 #{$calcSpace}px !important;
    }

    .my-#{$space} {
        margin: #{$calcSpace}px 0 !important;
    }

    .px-#{$space} {
        padding: 0 #{$calcSpace}px !important;
    }

    .py-#{$space} {
        padding: #{$calcSpace}px 0 !important;
    }

    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$calcSpace}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$calcSpace}px !important;
        }
    }
}