@import "./variables";
@import "./breakpoint-mixins";

.container {
    margin-left: auto;
    margin-right: auto;
    width: $breakpoint-xs-max;

    @include xs {
        width: 400px;
    }
}

.hidden {
    visibility: hidden;
    display: none;
}

.text-center {
    text-align: center;
}

.text-upper {
    text-transform: uppercase;
}

.text-bold {
    font-weight: bold;
}

/*== 12 column grid width */
.d-flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}
.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
}
.col-1 {
    width: 8.333333%;
}
.col-2 {
    width: 16.66667%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.33333%;
}
.col-5 {
    width: 41.66667%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.33333%;
}
.col-8 {
    width: 66.66667%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.33333%;
}
.col-11 {
    width: 91.66667%;
}
.col-12 {
    width: 100%;
}
