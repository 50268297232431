@use "./theme/theme" as dlc-t;

.dlc-nav-list-item {
    $bg-color: dlc-t.get-primary-color(100);
    $text-color: dlc-t.get-primary-color("100-contrast");

    background-color: $bg-color;
    color: $text-color;

    margin-bottom: 16px;

    &.selected {
        background-color: dlc-t.get-primary-color(500) !important;
    }
}

.dlc-single-select-list {
    .mat-list-option {
        background-color: #fff;
        margin-bottom: 8px;

        .mat-list-text {
            color: #000;
        }

        border: 1px solid dlc-t.get-accent-color(500);

        &.mat-list-single-selected-option {
            background-color: dlc-t.get-green-palette-color(200);

            .mat-list-text {
                color: dlc-t.get-green-palette-color("200-contrast");
            }
        }
    }
}
