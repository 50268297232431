@use './theme/theme' as dlc-t;

$success-color:rgb(3, 167, 3);
$error-color: dlc-t.get-warn-color(500);

.success-snackbar {
    $textColor: dlc-t.get-primary-color('500-contrast');

    background-color: $success-color !important;
    color: $textColor !important;
}

.fail-snackbar {
    $textColor: dlc-t.get-warn-color('500-contrast');

    background-color: $error-color !important;
    color: $textColor !important;

    .mat-simple-snackbar-action {
        color: $textColor !important;
    }
}